<template>
    <div class="notification-center">
        <vs-dropdown vs-custom-content vs-trigger-click>
            <a href="#" @click.prevent="() => {}">
                <vs-button color="primary" type="flat" class="notification-center--action">
                    <font-awesome-icon :icon="bellIcon" class="notification-center--action--icon"/>
                    <span class="badge" v-if="unreadCount !== 0"></span>
                </vs-button>
            </a>
            <vs-dropdown-menu class="vx-navbar-dropdown">
                <h5 class="text-center">Notifications</h5>
                <div class="notification-center--drawer">
                    <Notifications mode="dropdown"/>
                </div>
                <div class="text-center">
                    <vs-button type="border" size="small" class="pa-1 w-full" disabled>Coming Soon</vs-button>
                </div>
            </vs-dropdown-menu>
        </vs-dropdown>
    </div>
</template>

<script>
import Notifications from '@/views/components/Notifications.vue';
import { faBell } from '@fortawesome/free-regular-svg-icons';

import { Request } from '@/utils/requests';

export default {
    data() {
        return {
            unreadCount: 0
        }
    },
    components: {
        Notifications
    },
    computed: {
        bellIcon: () => faBell
    },
    mounted() {
        this.loadNotificationsCount()
    },
    methods: {
        loadNotificationsCount() {
            let url = this.$store.state.apiendpoints.notifications

            Request(this, 'GET', url, {limit: 1, is_read: 'false'}).then(
                (res) => {
                    this.unreadCount = res.count
                },
                (err) => {
                    this.$vs.notify({
                        time: 2500,
                        title: 'Error',
                        text: "Error while loading notifications",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }
            )
        }
    }
}
</script>

<style lang="scss">
    .notification-center {
        padding-right: 20px;
        &--action {
            padding: 0 !important;
            margin: 0 !important;
            position: relative;

            &--icon {
                font-size: 1.5em;
                z-index: 1;
            }
            .badge {
                font-size: 0.6em;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;
                background-color: RGB(var(--vs-danger));
                width: 1.2em;
                height: 1.2em;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;

            }
        }

        &--drawer {
            width: 275px;
            max-height: 65vh;
            overflow: auto;
        }
    }
</style>

<template>
    <div class="layout--main" :class="[navbarClasses, footerClasses, {'app-page': isAppPage}]">
        <vs-popup
            :active="!$store.state.is_online"
            background-color="warning"
            :button-close-hidden="true"
            title="Internet Connection Lost"
            class="text-center"
        >
            We can't connect to Legully Servers. Please check your internet connection. We'll try connecting meanwhile.
        </vs-popup>
        <!-- <vx-tour :steps="steps" v-if="!disableThemeTour" /> -->

        <the-customizer
            @updateNavbar="updateNavbar"
            @updateNavbarColor="updateNavbarColor"
            :navbarType="navbarType"
            :navbarColor="navbarColor"
            :footerType="footerType"
            @updateFooter="updateFooter"
            :routerTransition="routerTransition"
            @updateRouterTransition="updateRouterTransition"
            v-if="!disableCustomizer"
            :hideScrollToTop="hideScrollToTop"
            @toggleHideScrollToTop="toggleHideScrollToTop"
            />
        <vx-sidebar :sidebarItems="sidebarItems" :logo="require('@/assets/images/logo/logo-dark.png')" title="Vuesax" parent=".layout--main" />

        <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">

            <div id="content-overlay"></div>

            <div class="content-wrapper">

                <the-navbar :navbarColor="navbarColor" :class="[{'text-white': isNavbarDark && !isThemeDark}, {'text-base': !isNavbarDark && isThemeDark}]" />

                <div class="router-view">
                    <div class="router-content" :class="{'mt-0': navbarType == 'hidden'}">
                        <transition :name="routerTransition">
                        <div class="router-header flex flex-wrap items-center mb-6" v-if="$route.meta.breadcrumb || $route.meta.pageTitle">
                            <div class="content-area__heading" :class="{'pr-4 border-0 md:border-r border-t-0 border-b-0 border-l-0 border-solid border-grey-light' : $route.meta.breadcrumb}">
                                <h2 class="mb-1">{{ routeTitle }}</h2>
                            </div>

                            <!-- BREADCRUMB -->
                            <vx-breadcrumb staticClass="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" />
                            <hr />
                        </div>
                        </transition>
                        <div class="content-area__content">
                            <back-to-top bottom="5%" visibleoffset="500" v-if="!hideScrollToTop">
                                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg" />
                            </back-to-top>
                            <transition :name="routerTransition" mode="out-in">
                                <router-view @changeRouteTitle="changeRouteTitle"></router-view>
                            </transition>
                        </div>
                    </div>
                </div>

            </div>

            <the-footer></the-footer>
        </div>
    </div>
</template>

<script>
import VxSidebar from '@/layouts/components/vx-sidebar/VxSidebar.vue';
import TheCustomizer from "../components/customizer/TheCustomizer.vue";
import TheNavbar from '../components/TheNavbar.vue';
import TheFooter from '../components/TheFooter.vue';
import themeConfig from '@/../themeConfig.js';
import sidebarItems from "@/layouts/components/vx-sidebar/sidebarItems.js";
import BackToTop from 'vue-backtotop'
import { Request } from '../../utils/requests';
const VxTour = () => import('@/components/VxTour.vue')

export default {
    data() {
        return {
            requestingNotifications: false,
            navbarType: themeConfig.navbarType || 'floating',
            navbarColor: themeConfig.navbarColor || '#fff',
            footerType: themeConfig.footerType || 'static',
            routerTransition: themeConfig.routerTransition || 'none',
            isNavbarDark: false,
            routeTitle: this.$route.meta.pageTitle,
            sidebarItems: sidebarItems,
            disableCustomizer: themeConfig.disableCustomizer,
            windowWidth: window.innerWidth, //width of windows
            hideScrollToTop: themeConfig.hideScrollToTop,
            disableThemeTour: themeConfig.disableThemeTour,
            // steps: [
            //     {
            //         target: '#btnSidebarToggler',
            //         content: 'Toggle Collapse Sidebar.'
            //     },
            //     {
            //         target: '.vx-navbar__starred-pages',
            //         content: 'Create your own bookmarks. You can also re-arrange them using drag & drop.'
            //     },
            //     {
            //         target: '.i18n-locale',
            //         content: 'You can change language from here.'
            //     },
            //     {
            //         target: '.navbar-fuzzy-search',
            //         content: 'Try fuzzy search to visit pages in flash.'
            //     },
            //     {
            //         target: '.customizer-btn',
            //         content: 'Customize template based your preference',
            //         params: {
            //             placement: 'left'
            //         }
            //     },
            //     {
            //         target: '.vs-button.buy-now',
            //         content: 'Buy this awesomeness at affordable price!',
            //         params: {
            //             placement: 'top'
            //         }
            //     },
            // ]
        }
    },
    watch: {
        '$route'() {
            this.routeTitle = this.$route.meta.pageTitle;
        },
        isThemeDark(val) {
            if(this.navbarColor == "#fff" && val) {
                this.updateNavbarColor("#10163a")
            }else {
                this.updateNavbarColor("#fff")
            }
        },
        '$store.state.site_title'() {
            console.log('change')
            let title = this.$store.state.site_title
            if(title == null)
                title = 'Dashboard'
            document.title = title + " | Legully"
        }
    },
    computed: {
        isAppPage() {
            if(this.$route.path.includes('/apps/')) return true
            else return false
        },
        isThemeDark() { return this.$store.state.theme == 'dark' },
        sidebarWidth() {
            return this.$store.state.sidebarWidth;
        },
        bodyOverlay() {
            return this.$store.state.bodyOverlay;
        },
        contentAreaClass() {
            if(this.sidebarWidth == "default") return "content-area-default"
            else if(this.sidebarWidth == "reduced") return "content-area-reduced"
            else if(this.sidebarWidth) return "content-area-full"
        },
        navbarClasses() {
            return {
                'navbar-hidden': this.navbarType == 'hidden',
                'navbar-sticky': this.navbarType == 'sticky',
                'navbar-static': this.navbarType == 'static',
                'navbar-floating': this.navbarType == 'floating',
            }
        },
        footerClasses() {
            return {
                'footer-hidden': this.footerType == 'hidden',
                'footer-sticky': this.footerType == 'sticky',
                'footer-static': this.footerType == 'static',
            }
        },
    },
    methods: {
        changeRouteTitle(title) {
            this.routeTitle = title;
        },
        updateNavbar(val) {
            if(val == 'static') this.updateNavbarColor("#fff")
            this.navbarType = val;
        },
        updateNavbarColor(val) {
            this.navbarColor = val;
            if(val == "#fff") this.isNavbarDark = false
            else this.isNavbarDark = true
        },
        updateFooter(val) {
            this.footerType = val;
        },
        updateRouterTransition(val) {
            this.routerTransition = val;
        },
        handleWindowResize(event) {
            this.windowWidth = event.currentTarget.innerWidth;
            this.setSidebarWidth();
        },
        setSidebarWidth() {
            if (this.windowWidth < 1200) {
                this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', false)
                this.$store.dispatch('updateSidebarWidth', 'no-sidebar')
                this.disableThemeTour = true;
            }
            else if(this.windowWidth < 1200) {
                this.$store.dispatch('updateSidebarWidth', 'reduced')
            }
            else {
                this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true)
            }
        },
        toggleHideScrollToTop(val) {
            this.hideScrollToTop = val;
        },
        // requestDesktopNotifications() {
        //     this.requestingNotifications = true
        //     this.$vs.dialog({
        //         type: 'confirm',
        //         color: 'primary',
        //         title: 'Enable Notifications',
        //         text: 'Would you like to enable push notifications and recieve instant notifications?',
        //         accept: this.desktopNotificationsAllowed,
        //         'accept-text': 'Yes',
        //         'cancel-text': 'No'
        //     })
        // },
        // desktopNotificationsAllowed() {
        //     this.requestingNotifications = false
        //     if(!this.$fcmMessaging) return
        //     // Request Permission of Notifications
        //     this.$fcmMessaging.requestPermission().then(() => {
        //         this.$store.dispatch('updateFCMProgress', 'granted')
        //         this.$fcmMessaging.getToken().then((token) => {
        //             this.saveFCMToken(token)
        //         })
        //     }).catch( (err) => {
        //         this.$vs.notify({
        //             time: 2500,
        //             title: 'Error',
        //             text: "An error occured while enabling desktop notifications. Please try again.",
        //             iconPack: 'feather',
        //             icon: 'icon-alert-circle',
        //             color: 'danger'
        //         })
        //         throw err
        //     })

        //     this.$fcmMessaging.onTokenRefresh(function () {
        //         this.$fcmMessaging.getToken().then(function (newToken) {
        //             this.saveFCMToken(newToken)
        //         })
        //     })
        // },
        // saveFCMToken(token) {
        //     let payload = {
        //         'token': token,
        //         'user': (this.$store.getters.userInfo)?(this.$store.getters.userInfo.id):null
        //     }

        //     Request(this, 'POST', this.$store.state.apiendpoints.notifications_fcm, null, payload).then(
        //         (res) => {
        //             this.$vs.notify({
        //                 time: 4000,
        //                 title: 'Subscribed',
        //                 text: "Push Notifications has been enabled for this browser.",
        //                 iconPack: 'feather',
        //                 icon: 'icon-check-circle',
        //                 color: 'success'
        //             })
        //             this.$store.dispatch('updateFCMProgress', 'subscribed')
        //         },
        //         (err) => {
        //             this.$vs.notify({
        //                 time: 2500,
        //                 title: 'Error',
        //                 text: "An error occured while enabling desktop notifications. Please try again.",
        //                 iconPack: 'feather',
        //                 icon: 'icon-alert-circle',
        //                 color: 'danger'
        //             })
        //         }
        //     )
        // }
    },
    components: {
        VxSidebar,
        TheNavbar,
        TheFooter,
        TheCustomizer,
        BackToTop,
        // VxTour
    },
    created() {
        this.setSidebarWidth();
        if(this.navbarColor == "#fff" && this.isThemeDark) {
            this.updateNavbarColor("#10163a")
        }else {
            this.updateNavbarColor(this.navbarColor)
        }
        document.title = "Dashboard | Legully"
    },
    mounted() {
        // if ('Notification' in window && navigator.serviceWorker){
        //     if(Notification.permission == 'granted')
        //         this.$store.dispatch('updateFCMProgress', 'granted')
        //     if(Notification.permission != 'blocked' && this.$store.getters.fcmProgress != 'subscribed')
        //         this.requestDesktopNotifications()
        // }
    }
}
</script>

<template>
<div class="relative">
    <div class="vx-navbar-wrapper">
        <vs-navbar class="vx-navbar navbar-custom" :color="navbarColor" :class="classObj">

            <!-- SM - OPEN SIDEBAR BUTTON -->
            <feather-icon class="sm:inline-flex xl:hidden cursor-pointer mr-1" icon="MenuIcon" @click.stop="showSidebar"></feather-icon>

            <template v-if="breakpoint != 'md'">
                <div class="flex" :style="{flexDirection: 'column'}">
                    <template v-if="$store.state.organisation.entity">
                        <h4 class="mr-2">{{ $store.state.organisation.entity.name }}</h4>
                        <small class="text-primary">
                            {{ $store.state.organisation.organisation.name }}
                        </small>
                    </template>
                    <template v-else-if="$store.state.organisation.organisation">
                        <h4 class="mr-2">{{ $store.state.organisation.organisation.name }}</h4>
                    </template>
                    <template v-else>
                        <h4 class="mr-2">Dashboard</h4>
                    </template>
                </div>
            </template>


            <vs-spacer></vs-spacer>
            <vs-dropdown class="mr-4">
                <a class="a-icon" href="#" v-if="false">
                    Apps
                    <vs-icon class="" icon="expand_more"></vs-icon>
                </a>
                <vs-dropdown-menu>
                    <ul style="min-width: 15rem">
                        <!-- <router-link :to="{name: 'document-generator-config'}">
                            <li
                                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                            >
                                <feather-icon icon="FileTextIcon" svgClasses="w-4 h-4" />
                                <span class="ml-2">Doc Generator</span>
                            </li>
                        </router-link> -->
                        <!-- <router-link :to="{name: 'dsc-tracker'}">
                            <li
                                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                            >
                                <feather-icon icon="PieChartIcon" svgClasses="w-4 h-4" />
                                <span class="ml-2">DSC Tracker</span>
                            </li>
                        </router-link> -->

                        <router-link :to="{name: 'entitybulkupload'}">
                            <li
                                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                            >
                                <feather-icon icon="PenToolIcon" svgClasses="w-4 h-4" />
                                <span class="ml-2">Bulk Entity Uploader</span>
                            </li>
                        </router-link>

                        <router-link :to="{name: 'bulkimportupload'}">
                            <li
                                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                            >
                                <feather-icon icon="PenToolIcon" svgClasses="w-4 h-4" />
                                <span class="ml-2">Bulk Import</span>
                            </li>
                        </router-link>

                        <router-link :to="{name: 'bulk-password-manager'}">
                            <li
                                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                            >
                                <feather-icon icon="KeyIcon" svgClasses="w-4 h-4" />
                                <span class="ml-2">Bulk Password Uploader</span>
                            </li>
                        </router-link>

                    </ul>
                </vs-dropdown-menu>
            </vs-dropdown>
            <vs-dropdown class="mr-4">
                <a class="a-icon" href="#">
                    Get Help
                    <vs-icon class="" icon="expand_more"></vs-icon>
                </a>

                <vs-dropdown-menu>
                    <ul style="min-width: 15rem">
                        <a href="https://kb.legully.com/portal/home/" target="_blank">
                            <li
                                class="flex py-2 px-4 cursor-pointer"
                            >
                                <feather-icon icon="BookIcon" svgClasses="w-4 h-4"/>
                                <span class="ml-2">Knowledgebase</span>
                            </li>
                        </a>
                        <router-link :to="{name: 'help'}">
                            <li
                                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                            >
                                <feather-icon icon="HelpCircleIcon" svgClasses="w-4 h-4" />
                                <span class="ml-2">Contact Us</span>
                            </li>
                        </router-link>
                    </ul>
                </vs-dropdown-menu>
            </vs-dropdown>
            <Notifications />
            <!-- USER META -->
            <div class="the-navbar__user-meta flex items-center">
                <div class="text-right leading-tight hidden sm:block">
                    <p class="font-semibold">{{ user_displayName }}</p>
                    <small><strong>User ID:</strong> {{ user_id }}</small>
                </div>
                <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                    <div class="con-img ml-3">
                        <img
                            v-if="activeUserImg.startsWith('http')"
                            key="onlineImg"
                            :src="activeUserImg"
                            alt="user-img"
                            width="40"
                            height="40"
                            class="rounded-full shadow-md cursor-pointer block" />
                        <img
                            v-else
                            key="localImg"
                            :src="require(`@/assets/images/portrait/small/${activeUserImg}`)"
                            alt="user-img"
                            width="40"
                            height="40"
                            class="rounded-full shadow-md cursor-pointer block"
                        />
                    </div>
                    <vs-dropdown-menu class="vx-navbar-dropdown">
                        <ul style="min-width: 15rem">
                            <router-link :to="{name: 'reseller'}"  v-if="$store.state.auth.reseller">
                                <li
                                    class="flex py-2 px-4 cursor-pointer"

                                >
                                    <feather-icon icon="UsersIcon" svgClasses="w-4 h-4"/>
                                    <span class="ml-2">Reseller ID: {{ $store.state.auth.reseller.id }}</span>
                                </li>
                            </router-link>
                            <template v-if="false">
                              <a :href="$store.state.apiendpoints.profile">
                                  <li
                                      class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                                  >
                                      <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                                      <span class="ml-2">Profile</span>
                                  </li>
                              </a>
                              <vs-divider></vs-divider>
                              <a @click="fetch_data()">
                                  <li
                                      class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                                  >
                                      <feather-icon icon="CodeIcon" svgClasses="w-4 h-4" />
                                      <span class="ml-2">Refresh My Data</span>
                                  </li>
                              </a>
                              <router-link :to="{name: 'force-fetch-list'}">
                                  <li
                                      class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                                  >
                                      <feather-icon icon="LoaderIcon" svgClasses="w-4 h-4" />
                                      <span class="ml-2">View Requests</span>
                                  </li>
                              </router-link>
                              <vs-divider></vs-divider>
                            </template>
                            <li
                                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                                @click="$router.push({'name': 'page-logout'})"
                            >
                                <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
                                <span class="ml-2">Logout</span>
                            </li>
                        </ul>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </div>
        </vs-navbar>
    </div>
</div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import VxAutoSuggest from '@/components/vx-auto-suggest/VxAutoSuggest.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import Notifications from './Notifications.vue'

export default {
    name: "the-navbar",
    props: {
        navbarColor: {
            type: String,
            default: "#fff",
        },
    },
    data() {
        return {
            navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
            searchQuery: '',
            showFullSearch: false,
            unreadNotifications: [
                { index: 0, title: 'New Message', msg: 'Are your going to meet me tonight?', icon: 'MessageSquareIcon', time: this.randomDate({sec: 10}), category: 'primary' },
                { index: 1, title: 'New Order Recieved', msg: 'You got new order of goods.', icon: 'PackageIcon', time: this.randomDate({sec: 40}), category: 'success' },
                { index: 2, title: 'Server Limit Reached!', msg: 'Server have 99% CPU usage.', icon: 'AlertOctagonIcon', time: this.randomDate({min: 1}), category: 'danger' },
                { index: 3, title: 'New Mail From Peter', msg: 'Cake sesame snaps cupcake', icon: 'MailIcon', time: this.randomDate({min: 6}), category: 'primary' },
                { index: 4, title: 'Bruce\'s Party', msg: 'Chocolate cake oat cake tiramisu', icon: 'CalendarIcon', time: this.randomDate({hr: 2}), category: 'warning' },
            ],
            settings: { // perfectscrollbar settings
                maxScrollbarLength: 60,
                wheelSpeed: .60,
            },
            autoFocusSearch: false,
            showBookmarkPagesDropdown: false,
        }
    },
    watch: {
        '$route'() {
            if (this.showBookmarkPagesDropdown) this.showBookmarkPagesDropdown = false
        }
    },
    computed: {
        hide_analytics_button() {
            return window.location.host != this.$store.state.apiendpoints.analytics_hostname
        },
        // HELPER
        sidebarWidth() {
            return this.$store.state.sidebarWidth;
        },
        breakpoint() {
            return this.$store.state.breakpoint;
        },

        // NAVBAR STYLE
        classObj() {
            if (this.sidebarWidth == "default") return "navbar-default"
            else if (this.sidebarWidth == "reduced") return "navbar-reduced"
            else if (this.sidebarWidth) return "navbar-full"
        },

        // I18N
        getCurrentLocaleData() {
            const locale = this.$i18n.locale;
            if (locale == "en") return { flag: "us", lang: 'English' }
            else if (locale == "pt") return { flag: "br", lang: 'Portuguese' }
            else if (locale == "fr") return { flag: "fr", lang: 'French' }
            else if (locale == "de") return { flag: "de", lang: 'German' }
        },

        // BOOKMARK & SEARCH
        data() {
            return this.$store.state.navbarSearchAndPinList;
        },
        starredPages() {
            return this.$store.state.starredPages;
        },
        starredPagesLimited: {
            get() {
                return this.starredPages.slice(0, 10);
            },
            set(list) {
                this.$store.dispatch('arrangeStarredPagesLimited', list);
            }
        },
        starredPagesMore: {
            get() {
                return this.starredPages.slice(10);
            },
            set(list) {
                this.$store.dispatch('arrangeStarredPagesMore', list);
            }
        },

        // CART DROPDOWN
        cartItems() {
            return this.$store.state.eCommerce.cartItems.slice().reverse();
        },

        // PROFILE
        user_displayName() {
            return this.$store.getters.userInfo.first_name || 'Visitor'
        },
        user_id() {
            return this.$store.getters.userInfo.id || 'N/A'
        },
        activeUserImg() {
            return this.$store.getters.userInfo.avatar || this.$store.state.AppActiveUser.img;
        },
        CheckIcon: () => faCheckCircle
    },
    methods: {
        fetch_data(){
            this.$router.push({name: 'force-fetch'})
        },
        updateLocale(locale) {
            this.$i18n.locale = locale;
        },
        showSidebar() {
            this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
        },
        selected(item) {
            this.$router.push(item.url)
            this.showFullSearch = false;
        },
        actionClicked(item) {
            // e.stopPropogation();
            this.$store.dispatch('updateStarredPage', { index: item.index, val: !item.highlightAction });
        },
        showNavbarSearch() {
            this.showFullSearch = true;
        },
        showSearchbar() {
            this.showFullSearch = true;
        },
        elapsedTime(startTime) {
            let x = new Date(startTime);
            let now = new Date();
            var timeDiff = now - x;
            timeDiff /= 1000;

            var seconds = Math.round(timeDiff);
            timeDiff = Math.floor(timeDiff / 60);

            var minutes = Math.round(timeDiff % 60);
            timeDiff = Math.floor(timeDiff / 60);

            var hours = Math.round(timeDiff % 24);
            timeDiff = Math.floor(timeDiff / 24);

            var days = Math.round(timeDiff % 365);
            timeDiff = Math.floor(timeDiff / 365);

            var years = timeDiff;

            if (years > 0) {
                return years + (years > 1 ? ' Years ' : ' Year ') + 'ago';
            } else if (days > 0) {
                return days + (days > 1 ? ' Days ' : ' Day ') + 'ago';
            } else if (hours > 0) {
                return hours + (hours > 1 ? ' Hrs ' : ' Hour ') + 'ago';
            } else if (minutes > 0) {
                return minutes + (minutes > 1 ? ' Mins ' : ' Min ') + 'ago';
            } else if (seconds > 0) {
                return seconds + (seconds > 1 ? ' sec ago' : 'just now');
            }

            return 'Just Now'
        },
        logout() {
            // if user is logged in via auth0
            if (this.$auth.profile) this.$auth.logOut();

            // if user is looged in via firebase
            const firebaseCurrentUser = firebase.auth().currentUser

            if (firebaseCurrentUser) {
                firebase.auth().signOut().then(() => {
                    this.$router.push('/accounts/login')
                    localStorage.removeItem('userInfo');
                })
            }
            // Change role on logout. Same value as initialRole of acj.js
            this.$acl.change('admin')
            localStorage.removeItem('userRole');
        },
        outside: function() {
            this.showBookmarkPagesDropdown = false
        },

        // CART DROPDOWN
        removeItemFromCart(item) {
            this.$store.dispatch('eCommerce/toggleItemInCart', item)
        },

        // Method for creating dummy notification time
        randomDate({hr, min, sec}) {
          let date = new Date()

          if(hr) date.setHours(date.getHours() - hr)
          if(min) date.setMinutes(date.getMinutes() - min)
          if(sec) date.setSeconds(date.getSeconds() - sec)

          return date
        }
    },
    directives: {
        'click-outside': {
            bind: function(el, binding) {
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e)
                    }
                }
                el.__vueClickOutside__ = handler
                document.addEventListener('click', handler)
            },

            unbind: function(el) {
                document.removeEventListener('click', el.__vueClickOutside__)
                el.__vueClickOutside__ = null

            }
        }
    },
    components: {
        VxAutoSuggest,
        VuePerfectScrollbar,
        draggable,
        Notifications
    },
}
</script>
